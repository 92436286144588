h1,
h2,
h3,
h4,
h5 {
    margin: 2.75rem 0 1.05rem;
    // font-family: $font__main;
    font-weight: 400;
    line-height: 1.15;
}

h1 {
    margin-top: 0;
    font-size: 3.052em;
}

h2 {
    font-size: 2.441em;
}

h3 {
    font-size: 1.82em;
    margin: 0em 0 0.3em 0;
}

h4 {
    font-size: 1.45em;
    margin: 0em 0 0.3em 0;
}

h5 {
    font-size: 1.18em;
    margin: 0em 0 0.8em 0;
}
