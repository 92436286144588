.link-copied-wrap {
  display: inline-block;
  position: relative;
  span.link-copied {
    font-size: 1em;
    // @include media("<desktop") {
    //   font-size: 0.5rem;
    // }
    pointer-events: none;
    display: block;
    position: absolute;
    left: 30%;
    bottom: 175%;
    transform: translateX(-50%);

    & > span.inside {
      animation: popupAndDie 2.5s ease forwards;
      transform-origin: bottom center;
      @keyframes popupAndDie {
        0% {
          transform: scale(0.4) translateY(10.2em) translateX(2em) rotate(-80deg);
          // opacity: 0;
        }
        30% {
          transform: scale(1) translateY(0.8em) translateX(0em) rotate(-7deg);
          opacity: 1;
        }
        80% {
          transform: scale(1) translateY(0.8em) rotate(-7deg);
          opacity: 1;
        }
        100% {
          transform: scale(1.3) translateY(-1em) rotate(10deg);
          opacity: 0;
        }
      }

      padding: 0.8em 1.5em 1.2em;
      white-space: nowrap;
      display: inline-block;
      // background-image: url("/assets/images/copied-bubble.png");
      // background-image: url("../../images/copied-bubble.png");
      @extend %copied-bubble;
      
      
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      text-align: center;
    }
  }
}
.copy-this-for-me {
  position: relative;
}