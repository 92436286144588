body {
    @include media(">=360px") {
        font-size: 1.1em;
    }
    @include media(">=1300px") {
        font-size: 1.3em;
    }
    @include media(">=1500px") {
        font-size: 1.5em;
    }
    @include media(">=1700px") {
        font-size: 1.8em;
    }
    @include media(">=2000px") {
        font-size: 2em;
    }
    @include media(">=2500px") {
        font-size: 2.2em;
    }
}
