header {
  margin-bottom: auto;
  padding: 2em 0;
}

.content {
  text-align: center;
  // padding-bottom: 2em;
}

footer {
  margin-top: auto;
  @include media(">=1000px") {
    height: 1em;
    .footer-links {
    }
  }
  .footer-links {
    min-height: 6em;
    display: flex;
    justify-content: space-between;
    font-size: 0.75em;
    align-items: flex-end;
    a,
    button {
      text-align: start;
      color: #000;
      text-decoration: none;
      opacity: 0.4;
      display: inline-block;
      padding: 0.8em 1.1em;
      font-size: inherit;
      @include media(">=1000px") {
        position: fixed;
        bottom: 0;
        &:first-child {
          right: 0;
        }
        &:last-child {
          left: 0;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
