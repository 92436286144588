@font-face {
    font-family: $delicattes-katan;
    src: url("../../fonts/delicattes/FtDelicattesKatan-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: $delicattes-katan;
    src: url("../../fonts/delicattes/FtDelicattesKatan-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
html {
    font-size: 100%;
} /*16px*/

body,
button,
input,
select,
optgroup,
textarea {
    color: $color__text-main;
    font-family: $font__main;
    line-height: $font__line-height-body;
    font-feature-settings: "liga" 1, "calt" 1, "kern" 1;
}


body {
    background-color: white;
    font-weight: 400;
}

p {
    margin-bottom: 0.65em;
}

small,
.text_small {
    font-size: 0.75em;
}
