.social-icons {
  margin: 0.5em 0;
  // padding: 0 1em;
  .icon {
    display: inline-block;
    color: black;
    font-size: 2.2em;
    padding: 0.1em 0.15em;
    line-height: 0.7;
    cursor: pointer;
    // transition: transform 180ms ease;
    &:hover {
      transform: scale(1.15) rotate(-10deg);
      color: $main;
      // text-shadow: 0 0 1em black;
    }
    &:hover {
      &:nth-child(2n + 1) {
        transform: scale(1.15) rotate(10deg);
      }
    }
    i.fa-link:before {
      font-size: 0.88em;
    }
    i.fa-at:before {
      font-size: 0.95em;
    }
  }
}

// .social-icons {
//   display: flex;
//   justify-content: space-between;
//   margin-right: -0.4em;
//   @include media("<368px") {
//     font-size: 0.9em;
//   }
//   @include media("<330px") {
//     font-size: 0.85em;
//   }
//   a, button {
//     display: inline-block;
//     padding: 0.1em 0.12em;
//     text-align: center;
//     text-decoration: none;
//     cursor: pointer;
//     i {
//       color: black;
//       font-size: 2.5em;
//     }
//     span.small-text {
//       display: block;
//       font-size: 0.7em;
//       margin: 0.2em;
//       // color: black;
//     }
//     i.fa-link:before {
//       font-size: 0.88em;
//     }
//     i.fa-at:before {
//       font-size: 0.95em;
//     }

//     transition: transform 180ms ease;
//     &:hover {
//       transform: scale(1.15) rotate(-10deg);
//       i {
//         color: $white;
//         text-shadow: 0 0 1em black;
//       }
//     }
//     &:hover {
//       &:nth-child(2n + 1) {
//         transform: scale(1.15) rotate(10deg);
//       }
//     }
//   }
// }

// .share-part {
//   @include media(">=300px") {
//     font-size: 1.1em;
//   }
//   @include media(">=330px") {
//     font-size: 1.15em;
//   }
//   @include media(">=tablet") {
//     font-size: 1em;
//   }
// }
