.radio {
    display: inline-block;
    margin: 0 0.4em 0.5em;
    label {
        display: inline-block;
        &:hover {
            input[type="radio"] {
                & + span {
                    transform: scale(1.08) rotate(1deg);
                }
            }
        }
        &:active {
            input[type="radio"] {
                & + span {
                    transform: scale(0.98) rotate(-1deg);
                }
            }
        }
        cursor: pointer;
    }
    input[type="radio"] {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        & + span {
            position: relative;
            display: inline-block;
            height: 1.5em;
            width: 1.5em;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 24.2.3, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='30px' height='31.5px' viewBox='0 0 30 31.5' style='overflow:visible;enable-background:new 0 0 30 31.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23060000;stroke-width:0.87;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpath class='st0' d='M13.2,0.5c1.9,0.1,3.9,0.6,6.1,1.5c11.9,5,15.1,25,0.4,28.7c-6.3,1.6-13.2-2-16.5-7.6 C-3.1,12.3,1.8-0.3,13.2,0.5z'/%3E%3C/svg%3E%0A");
            top: 0.45em;
            margin-inline-end: 0.3em;
            &:before {
                top: 0em;
                left: 0.35em;
                content: "";
                position: absolute;
                display: inline-block;
                background-size: contain;
                background-repeat: no-repeat;
                height: 1.2em;
                width: 1.2em;
                background-image: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 24.2.3, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='31.3px' height='28.8px' viewBox='0 0 31.3 28.8' style='overflow:visible;enable-background:new 0 0 31.3 28.8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FDCD45;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cpath class='st0' d='M30.5,1.2c-0.3-0.4-0.7-0.8-1.3-1c-2.2-0.9-4.4,1.1-5.9,2.9c-4.5,5.2-9,10.4-13.6,15.5 c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.1-0.1-0.2-0.2c-1.2-1.9-2.4-3.7-3.5-5.6c-0.8-1.2-2.3-1.6-3.7-1c-4.1,1.7,0.1,8.9,1.6,11.1 c1,1.5,1.9,3.1,3.3,4.2c1.4,1.1,3.3,1.8,5,1.3c1.5-0.5,2.6-1.8,3.6-3c4.4-5.4,8.8-10.9,13.3-16.3c1.1-1.4,2.3-2.8,2.7-4.6 C31.4,3.5,31.2,2.1,30.5,1.2z'/%3E%3C/svg%3E%0A");
                transform: scale(0) rotate(-30deg);
                transform-origin: 20% 80%;
            }
        }
        &:checked + span {
            &:before {
                transform: scale(1);
                transition: transform 270ms ease-out;
            }
        }
    }
}
