html {
    direction: rtl;
    height: 100%;
}
img {
    // display: inline-block;
}

// html, body {
//     min-height: 100%;
//     height: 100%;
// }
html,
body,
#root,
#root > div {
    min-height: 100%;
    align-items: stretch;
    display: flex;
    flex-flow: column;
    flex: 1;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
}
#root {
    // flex-grow: 1;
    // display: inline-flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-content: center;
    // flex-flow: column;

    // background: green;
    > div {
        // flex-grow: 1;
        // display: flex;
        // height: auto;
        // justify-content: center;
    }
}
.min-height-100vh {
    // min-height: 100vh !important;
    // min-height: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    // min-height: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    // min-height: stretch !important;
}
// #root {
//     background: yellow;
//     display: table-cell;
//     height: 100%;
//     vertical-align: middle;
// }
