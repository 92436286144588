a {
    color: rgb(102, 133, 214);
}

a.underline {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: black;
    > span {
        position: relative;
    }
    &:before {
        content: "";
        pointer-events: none;
        display: block;
        height: 0.19em;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        // background-image: url("/assets/images/underline.png");
        @extend %underline;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    &:hover {
        transform: scale(1.05) rotate(-1deg);
    }
}
