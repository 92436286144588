.walk-in-letters {
  $initial_wait: 0ms;
  $duration: 850ms;
  $delay_between_each: 24ms;
  span.letter {
    display: inline-block;
    transform-origin: center;
    transform: scale(0);
    @keyframes WalkInLetters {
      // 0% {
      //   transform: translateY(0.4em) translateX(0em) rotate(30deg) scale(0);
      // }
      // 50% {
      //   transform: translateY(-0.3em) translateX(0em) rotate(0deg) scale(0.9);
      // }
      // 75% {
      //   transform: translateY(0.1em) translateX(0em) rotate(-10deg) scale(0.7);
      // }
      // 100% {
      //   transform: translateY(0em) translateX(0em) rotate(0deg) scale(1);
      // }
      0% {
        transform: translateY(0.2em) translateX(0em) rotate(0deg) scale(0);
      }
      50% {
        transform: translateY(-0.1em) translateX(0em) rotate(0deg) scale(1);
      }
      75% {
        transform: translateY(0.1em) translateX(0em) rotate(0deg) scale(0.9);
      }
      100% {
        transform: translateY(0em) translateX(0em) rotate(0deg) scale(1);
      }
    }
    animation: WalkInLetters $duration ease-in-out alternate forwards;
    @for $i from 0 through 80 {
      &:nth-child(#{$i}) {
        animation-delay: $initial_wait + $delay_between_each * $i;
      }
    }
  }
}
