

.nowrap {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}
.text-start {
    text-align: start;
}

.mb-3 {
    margin-bottom: 1.8em;
}

.ltr {
    direction: ltr;
}