.loading-wrap {
    .loading-inside {
        .loading-dots {
            .dot {
                display: inline-block;
                background: black;
                border-radius: 100%;
                height: 0.2em;
                width: 0.23em;
                margin: 0 0.12em;

                $initial_wait: -1400ms;
                $duration: 1300ms;
                $delay_between_each: 80ms;

                transform-origin: center;
                @keyframes loadingDots {
                    0% {
                        transform: translateY(0em);
                    }
                    30% {
                        transform: translateY(0.4em);
                    }
                    // 50% {
                    //     transform: translateY(-0.15em);
                    // }
                    100% {
                        transform: translateY(0em);
                    }
                }
                animation: loadingDots $duration ease-in-out infinite;
                @for $i from 0 through 40 {
                    &:nth-child(#{$i}) {
                        animation-delay: $initial_wait + $delay_between_each * $i;
                    }
                }
            }
        }
        .loading-text {
            margin-top: 0.3em;
            white-space: nowrap;
        }
        .afikoman {
            margin-top: -0.3em;
        }
    }
}
