.field-wrap {
    label {
        display: block;
        margin: 0 0 0.5em;
        font-weight: 700;
        font-size: 0.8em;
    }
    .input-wrap {
        input,
        textarea {
            background: none;
            width: 100%;
            display: block;
            padding: 0.5em 0.8em;
            border: $darkhr 1px solid;
            font-size: 1em;
            color: white;
            border-radius: 0.1em;
            box-sizing: border-box;
            &::placeholder {
                color: $icontext;
            }
            &:focus {
                outline: none;
                border-color: $yellow;
            }
        }
        textarea {
            overflow: hidden !important;
            min-height: 3.8em;
        }
        &.flex {
            display: flex;
            margin: 0 0 0.3em;
            font-size: 0.8em;
            button {
                border-radius: 0 0.3em 0.3em 0;
                white-space: nowrap;
                // padding: 0.6em 1em;
            }
            input {
                border-radius: 0.3em 0 0 0.3em;
                font-size: 0.8em;
                padding: 0;
                text-indent: 0.8em;
                direction: ltr;
            }
        }
    }
}
.text-flex {
    display: flex;
    margin: 0 -0.1em;
    margin-top: 1em;
    .button {
        text-align: center;
        flex-grow: 1;
    }
    .seperator {
        display: block;
        width: 1em;
    }
}

.error {
    font-size: 0.8em;
    color: $red;
    margin: 0.5em 0 0.3em;
    @keyframes popIn {
        0% {
            transform: scale(0.7);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    animation: popIn 100ms ease-out forwards;
}

textarea,
input[type="text"] {
    &.blink-input {
        &:not(:focus) {
            // border-color: white;
            // border: $darkhr 1px solid;
            @keyframes blinkBorder {
                0% {
                    // transform: scale(1);
                    // opacity: 0.7;
                    box-shadow: 0 0 0 0 $darkhr;
                }
                50% {
                    box-shadow: 0 0 0.5em -0.2em $darkhr;
                    // transform: scale(1.7);
                    // opacity: 0;
                    border-color: $darkhr;
                }
                100% {
                    box-shadow: 0 0 1.2em -0.2em $yellow;
                    border-color: $yellow;
                    // transform: scale(1.7);
                    // opacity: 0;
                }
            }
            animation: blinkBorder 1.2s infinite alternate ease-in-out;
        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 0.05em black inset !important;
    border-radius: 100em;
}

input[type="text"].url {
    font-size: 0.8em;
    // background-image: url("/assets/images/input-bg-1.png");
    @extend %input-bg-1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    padding: 0.5em 1em;
    min-width: 10em;
    text-indent: 1.3em;
    font-family: $font__main;
    &.ltr {
        direction: ltr;
        &::placeholder {
            direction: ltr;
        }
    }
    outline: none;
    &.big {
        // font-size: 1.3em;
        padding: 1.3em 0em;
        min-width: 21em;
    }
}
input[type="text"] {
    font-size: 0.8em;
    // background-image: url("/assets/images/input-bg-2.png");
    @extend %input-bg-2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    padding: 0.5em 1em;
    min-width: 10em;
    text-indent: 1.3em;
    font-family: $font__main;
    &.ltr {
        direction: ltr;
    }
    outline: none;
    &.big {
        // font-size: 1.3em;
        padding: 0.8em 0em;
        min-width: 15em;
    }
    &::placeholder {
        direction: rtl;
    }
}
.custom-id {
    direction: ltr;
    margin-bottom: 0.4em;
    .preurl {
        display: inline-block;
        margin-inline-end: 0.3em;
    }
    .error {
        direction: rtl;
    }
    // display: flex;
}
